@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Space Grotesk", sans-serif;
}
.Sidebar {
  color: #275ce1;
}
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root {
  @apply h-full w-full m-0 p-0;
}
