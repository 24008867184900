@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap");

*::before,
*::after {
  box-sizing: border-box;
}

/*
Typographic tweaks!
3. Add accessible line-height
*/
body {
  line-height: 1.5;
}
/*
4. Improve media defaults
*/
img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}
/*
6. Remove built-in form typography styles
*/
input,
button,
textarea,
select {
  font: inherit;
}
/*
7. Avoid text overflows
*/
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}
/*
8. Create a root stacking context
*/
#root,
#__next {
  isolation: isolate;
}

input {
  padding: 10px 20px;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-bottom: 15px;
}

a {
  text-decoration: none;
}

.register,
.login,
.home,
.modal {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  // min-height: 100vh;
  padding: 30px;
}
// .infinite-scroll-component {
// }

.infinite-scroll-component__outerdiv {
  flex-grow: 1;
}
.loginTitle {
  margin-bottom: 30px;
}

.registerForm,
.loginForm {
  width: 80%;
  display: flex;
  flex-direction: column;
}

.homeBtn,
.modalBtn {
  display: block;
  width: 200px;
  padding: 15px;
  cursor: pointer;
  outline: none;
  border: none;
  border-radius: 3px;
  font-size: 16px;
  font-weight: bold;
  color: #fffbf5;
  background-color: #674188;
  margin-bottom: 15px;
}

.homeForm {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  margin-bottom: 30px;
}

.homeBtn {
  margin-bottom: 0px;
}

.thread__container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 20px;
}

.thread__item {
  padding: 20px;
  width: 80%;
  box-shadow: 0 0 1px 1px #c3acd0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.likes__container {
  display: flex;
  align-items: center;
  margin-right: 25px;
}

.likesBtn {
  height: 30px;
  color: #f99417;
  cursor: pointer;
}

.react__container {
  display: flex;
  align-items: center;
}

.modal {
  background-color: #ddd;
  position: fixed;
}

.modalInput {
  width: 100%;
  padding: 15px;
  margin-bottom: 15px;
}

.modal__content {
  background-color: #fff;
  width: 70%;
  padding: 30px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.modal__content > label {
  margin-bottom: 15px;
}

.navbar {
  width: 100%;
  padding: 10px 20px;
  background-color: #674188;
  display: flex;
  height: 10vh;
  align-items: center;
  justify-content: space-between;
}

.navbar > h2 {
  color: #fffbf5;
}

.navbarRight {
  display: flex;
  align-items: center;
}

.navbarRight > button {
  padding: 15px;
  width: 150px;
  background-color: #f99417;
  color: #fffbf5;
  font-size: 16px;
  border: none;
  outline: none;
  margin-left: 15px;
  cursor: pointer;
  border-radius: 3px;
}
